// Empty Value
export const EMPTY_VALUE = '--'
export const NOT_APPLICABLE = 'n/a'
export const NOT_APPLICABLE_UPPER = 'N/A'
export const DEFAULT_AUTHOR = 'Agrible'

// COUNTRIES
const US = 'US' // United States
const CA = 'CA' // Canada
const AU = 'AU' // Australia
/**
 * A dictionary of supported countries. (US, CA)
 */
export const COUNTRIES = Object.freeze({
  US,
  CA,
  AU
})

// API STATUS
const ERROR = 'error'
const SUCCESS = 'success'

export const API_STATUS = Object.freeze({
  ERROR,
  SUCCESS
})

// UNITS
const METRIC = 'Metric'
const IMPERIAL = 'Imperial'
export const LIQUID = 'Liquid'
export const DRY = 'Dry'
export const WET = 'Wet'

export const ACRE_TO_HECTARE_RATIO = 0.40468564224

/*
  A dictionary of evidence types in relation to field story event
*/

export const FS_EVENT = Object.freeze({
  CHEMICAL: 'Chemical',
  FERTILIZER: 'Fertilizer',
  HARVEST: 'Harvest',
  IRRIGATION: 'Irrigation',
  PLAN_DRAWER: 'Plan Drawer',
  PLANTING: 'Planting',
  SAMPLING: 'Sampling',
  TILLAGE: 'Tillage'
})

// Defualt protection application units
const LIQUID_US_UNITS = 'US_liquid_ounce/ac'
const LIQUID_METRIC_UNITS = 'mL/ha'
const DRY_US_UNITS = 'oz/ac'
const DRY_METRIC_UNITS = 'kg/ha'
const LARGE_LIQUID_US_UNITS = 'lbs/US_liquid_gallon'
const LARGE_LIQUID_METRIC_UNITS = 'kg/L'

const LARGE_LIQUID_FORMATED_US = 'lbs active ingredient / gallon'
const LARGE_LIQUID_FORMATED_METRIC = 'kg active ingredient / liter'

export const SMALL_DEFAULT_DRY_UNITS = {
  Imperial: DRY_US_UNITS,
  Metric: DRY_METRIC_UNITS
}

export const SMALL_DEFAULT_LIQUID_UNITS = {
  Imperial: LIQUID_US_UNITS,
  Metric: LIQUID_METRIC_UNITS
}
export const LARGE_DEFAULT_LIQUID_UNITS = {
  Imperial: LARGE_LIQUID_US_UNITS,
  Metric: LARGE_LIQUID_METRIC_UNITS
}
export const LARGE_DEFAULT_UNITS_FORMATTED = {
  Imperial: LARGE_LIQUID_FORMATED_US,
  Metric: LARGE_LIQUID_FORMATED_METRIC
}

/**
 * A dictionary of supported units. (Metric, Imperial)
 */
export const UNITS = Object.freeze({
  METRIC,
  IMPERIAL
})

export const AREA_UNITS = Object.freeze({
  [IMPERIAL]: 'ac',
  [METRIC]: 'ha'
})

export const AREA_UNITS_FULL = Object.freeze({
  [IMPERIAL]: 'acres',
  [METRIC]: 'hectares'
})
// Roles
const Grower = 'Grower'
const Stakeholder = 'Stakeholder'
const EmployeeManager = 'Manager'
const EmployeeAdmin = 'Employee Admin'
const Auditor = 'Auditor'
const Employee = 'Employee'

export const Roles = Object.freeze({
  Grower,
  Stakeholder,
  EmployeeManager,
  Auditor,
  Employee,
  EmployeeAdmin
})

// Role Templete Ids
const GrowerRoleId = 5
const StakeholderRoleId = 11
const AuditorRoleId = 12
const EmployeeRoleId = 13
const EmployeeManagerRole = {
  campaignManagerRoleId: 3,
  regionManagerRoleId: 4
}

export const RolesTemplateIds = Object.freeze({
  GrowerRoleId,
  StakeholderRoleId,
  EmployeeManagerRole,
  AuditorRoleId,
  EmployeeRoleId
})

export const RolesNamesIds = {}

RolesNamesIds[Roles.Grower] = RolesTemplateIds.GrowerRoleId
RolesNamesIds[Roles.Stakeholder] = RolesTemplateIds.StakeholderRoleId
RolesNamesIds[Roles.Auditor] = RolesTemplateIds.AuditorRoleId
RolesNamesIds[Roles.Employee] = RolesTemplateIds.EmployeeRoleId

// Report Types
const CPGReport = 'CPG'
const FieldBoundariesReport = 'Field Boundaries'
const GrowerReport = 'Grower'
const ProjectStatusReport = 'Project Status'
const QCReport = 'QC'
const CarbonContractInfoReport = 'Carbon Contract Info'
const ConsolidatedCarbonContractInfoReport = 'ConsolidatedCarbonContractInfo'
const EudrReport = 'EUDR'
const GrowerCarbonSummary = 'GrowerCarbonSummary'
const InsuranceInfo = 'Insurance Info'

export const ReportTypes = Object.freeze({
  CPGReport,
  FieldBoundariesReport,
  GrowerReport,
  GrowerCarbonSummary,
  ProjectStatusReport,
  QCReport,
  CarbonContractInfoReport,
  ConsolidatedCarbonContractInfoReport,
  InsuranceInfo,
  EudrReport
})

// CROPS
const SOYBEANS = 'Soybeans'
const CORN_GRAIN = 'Corn (grain)'
const CORN_SILAGE = 'Corn (silage)'
const BARLEY = 'Barley'
const WHEAT = 'Wheat'
const SORGHUM = 'Sorghum'
const LENTILS = 'Lentils'
const CANOLA = 'Canola'
const CHICKPEAS = 'Chickpeas'
const DRYBEANS = 'Dry Beans'
const DRYPEAS = 'Dry Peas'
const COTTON = 'Cotton'
const PEANUTS = 'Peanuts'
const POTATOES = 'Potatoes'
const RYE = 'Rye'
const RICE = 'Rice'
const ALFALFA = 'Alfalfa'
const OTHER_CROP = 'other-crop'
const COVER_CROP = 'cover-crop'
const OATS = 'Oats'
const SUGARBEETS = 'Sugarbeets'
const TOMATOES = 'Tomatoes'
const FALLOW = 'Fallow'

/**
 * A dictionary of crops
 */
export const CROPS = Object.freeze({
  SOYBEANS,
  CORN_GRAIN,
  CORN_SILAGE,
  BARLEY,
  WHEAT,
  CANOLA,
  COTTON,
  SUGARBEETS,
  TOMATOES,
  PEANUTS,
  POTATOES,
  OTHER_CROP,
  COVER_CROP,
  FALLOW
})
export const HIGH_LOSS_CROPS = Object.freeze({
  Potatoes: POTATOES
})

const ORGS = 'orgs'
const ENTERPRISE_GROUPS = 'enterprise_groups'
const FARMS = 'farms'
const FIELDS = 'fields'
const ROTATION_GROUP = 'ROTATION_GROUP'

export const ENTITY_TYPES = Object.freeze({
  ORGS,
  ENTERPRISE_GROUPS,
  FARMS,
  FIELDS,
  ROTATION_GROUP
})

/**
 * A dictionary of crops that are measured in bushels
 */
export const BUSHEL_CROPS = Object.freeze([
  SOYBEANS,
  CORN_GRAIN,
  BARLEY,
  WHEAT,
  CANOLA,
  SORGHUM,
  LENTILS,
  DRYBEANS,
  CHICKPEAS,
  DRYPEAS,
  RYE,
  RICE,
  ALFALFA,
  OATS
])

/**
 * A dictionary Commitment Episode Types
 **/
const AS_APPLIED = 'AA'
const BASELINE = 'BL'
export const EPISODE_TYPE = Object.freeze({ AS_APPLIED, BASELINE })

const COMPLETED = 'completed'
const INCOMPLETE = 'incomplete'
const EVIDENCE_REQUESTED = 'evidence requested'
export const BASELINE_SEASON_STATUS = Object.freeze({
  COMPLETED,
  INCOMPLETE,
  EVIDENCE_REQUESTED
})

const FERTILIZER_APPLICATION_BASELINE = 'Fertilizer Application'
const FERTILIZERAPPLICATION_BASELINE = 'FertilizerApplication'
const HARVEST_COMPLETION_BASELINE = 'Harvest Completion'
const HARVESTCOMPLETION_BASELINE = 'HarvestCompletion'
const HARVEST_MIN = 'HarvestMin'

export const BASELINE_SEASON_EVENTS = Object.freeze({
  FERTILIZER_APPLICATION_BASELINE,
  FERTILIZERAPPLICATION_BASELINE,
  HARVEST_COMPLETION_BASELINE,
  HARVESTCOMPLETION_BASELINE,
  HARVEST_MIN
})

export const STEPPER_STATUS = Object.freeze({
  COMPLETED,
  INCOMPLETE
})

/**
 * A dictionary seed population units
 **/

const SEEDS_PER_AC = 'seeds/ac'
const SEEDS_PER_HA = 'seeds/ha'
const LBS_OF_SEED_PER_AC = 'lbs_of_seeds/ac'
const KGS_OF_SEED_PER_HA = 'kgs_of_seeds/ha'
const LBS_OF_SEED_PIECES_PER_AC = 'lbs_of_seed_pieces/ac'
const KGS_OF_SEED_PIECES_PER_HA = 'kgs_of_seed_pieces/ha'

const PLANTS_PER_AC = 'plants/ac'
const PLANTS_PER_HA = 'plants/ha'

export const POPULATION_UNITS = Object.freeze({
  PLANTS_PER_AC,
  PLANTS_PER_HA,
  SEEDS_PER_AC,
  SEEDS_PER_HA,
  LBS_OF_SEED_PER_AC,
  KGS_OF_SEED_PER_HA,
  LBS_OF_SEED_PIECES_PER_AC,
  KGS_OF_SEED_PIECES_PER_HA
})

const FUNGICIDE = 'Fungicide'
const INSECTICIDE = 'Insecticide'
const NOT_TREATED = 'Not Treated'
const PLANT_GROWTH_REGULATOR = 'Plant Growth Regulator (PGR)'
const UNSPECIFIED_TREATMENT = 'Unspecified Treatment'

export const SEED_TREATMENT = Object.freeze({
  FUNGICIDE,
  INSECTICIDE,
  NOT_TREATED,
  PLANT_GROWTH_REGULATOR,
  UNSPECIFIED_TREATMENT
})

export const DRAWER_TRANSITION_DURATION = 500

export const INDIVIDUAL_APPLICATION = 'Individual Application'
export const TOTAL_SEASON_APPLICATION = 'Total Season Application'

// Banner Types
const BANNER_ERROR = 'error'
const WARNING = 'warning'
const INFO = 'info'
export const BANNER_TYPES = Object.freeze({
  ERROR: BANNER_ERROR,
  WARNING,
  INFO
})

// Baseline Levels
const ROTATION = 'rotation'
const FIELD = 'field'

export const BASELINE_LEVELS = Object.freeze({
  ROTATION,
  FIELD
})

export const CAMPAIGN_TYPES = Object.freeze({
  NERP: 'NERP',
  SNO: 'SNO',
  MEAS: 'MEAS',
  EUDR: 'EUDR'
})

export const ENROLLED_STATUS = Object.freeze({
  FIELD_STATUS_ENROLLED: 'enrolledFields',
  FIELD_STATUS_ELIGIBLE: 'eligibleFields',
  FIELD_STATUS_INELIGIBLE: 'ineligibleFields'
})

export const REPORT_CARD = 'report-card'
